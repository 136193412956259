<template>
  <div class="l-tableList-collector">
    <b-row>
      <b-col md="6" />
      <b-col md="6">
        <div class="d-flex align-items-center justify-content-end">
          <button-dashed
            v-if="isDelete"
            ref="deleteBtn"
            :disabled="isBatchDeleteArrNotEmpty"
            :class="isBatchDeleteArrNotEmpty ? 'opacity-50': null"
            @click="deleteButtonHandler"
          >
            <feather-icon
              icon="LTrashIcon"
              size="22"
              class="lightIcon m-0"
            />
          </button-dashed>
          <button-dashed
            v-if="isImport"
            @click="importButtonHandler"
          >
            <feather-icon
              icon="LImportIcon"
              size="16"
              class="lightIcon"
            />
            {{ $t('Import') }}
          </button-dashed>
          <button-dashed v-if="isPrint">
            <feather-icon
              icon="LPrintIcon"
              size="16"
              class="lightIcon"
            />
            {{ $t('Print') }}
          </button-dashed>
          <slot name="filter">
            <feather-icon
              v-if="createPageUrl"
              icon="LAddButtonIcon"
              class="cursor-pointer"
              size="34"
              @click="gotoNewPage(createPageUrl, $event, createPageUrl.isBlank)"
            />
          </slot>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="6"
        class="d-flex justify-content-start"
      >
        <button-dashed
          v-if="filterClass"
          @click="filterToggle = !filterToggle"
        >
          <feather-icon
            icon="LFilterIcon"
            size="16"
            class="lightIcon"
          />
          <span class="filterTitle"> {{ $t('Filter By') }}</span>
          <feather-icon
            icon="LArrowDownIcon"
            size="16"
            class="defaultIconColor"
          />
        </button-dashed>
      </b-col>
    </b-row>
    <b-row v-if="filterClass">
      <b-col md="12">
        <component
          :is="`${moduleName}FilterComponent`"
          :filter-toggle="filterToggle"
          :filters="filters"
          :filter-class="filterClass"
          :selected-filters="selectedFilters"
          @onFilter="onFilter"
          @onRemoveSelectedFilter="refetchData"
        />
      </b-col>
    </b-row>
    <div>
      <slot name="table-top" />
    </div>
    <b-table
      :sort-by.sync="sortByTable"
      :sort-desc.sync="isSortDirDesc"
      v-bind="getTableProps()"
      :class="isRemoveHeader ? 'headerTable' : ''"
      :tbody-tr-class="rowActive.filedKey ? rowClassHandler : ''"
      @row-clicked="myRowClickHandler"
      @row-hovered="rowHoveredHandler"
      @row-unhovered="rowUnHoveredHandler"
    >
      <!--  Head Slots    -->
      <template
        v-for="head in headSlotNames"
        #[head]="data"
      >
        <slot
          :name="head"
          :data="data"
        >
          {{ data.label }}
        </slot>
      </template>
      <!--  Batch Delete    -->
      <template
        v-if="isDelete"
        #head(batchdelete)=""
      >
        <b-form-checkbox
          class="mt-0"
          style="margin-top: 1px !important;"
          :checked="isAllChecked"
          :disabled="tableList.length === 0"
          @change="getAllIds"
        />
      </template>
      <template
        v-if="isDelete"
        #cell(batchdelete)="data"
      >
        <b-form-checkbox
          :checked="isChecked(data.item.id)"
          @change="addToBatchArr(data.item.id)"
        />
      </template>
      <!--  Table Column Slots    -->
      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>
      <template
        v-for="cell in cellSlotNames"
        #[cell]="data"
      >
        <slot
          :name="cell"
          :data="data"
        >
          {{ data.value !== null && data.value !== undefined && data.value !== "" ? data.value: "&mdash;" }}
        </slot>
      </template>

      <template #cell(show_details)="row">
        <div class="d-flex">
          <slot
            name="cell(show_details)"
            :data="row"
          />
          <feather-icon
            v-b-tooltip.noninteractive.hover.bottom
            :title="$t('Note')"
            icon="LNoteIcon"
            class="lightIcon cursor-pointer border-dotted featherIcon"
            size="30"
            style="padding: 4px"
            @click="row.toggleDetails"
          />
        </div>
      </template>

      <template #row-details="row">
        <slot
          name="cell(show_row_details)"
          :data="row"
        />
      </template>

      <!--  Table Footer Slots    -->
      <template
        v-for="foot in footSlotNames"
        #[foot]="data"
        :v-if="isFooter"
      >
        <slot
          :name="foot"
          :data="data"
        >
          {{ }}
        </slot>
      </template>
      <!--  Table Busy    -->
      <template #table-busy>
        <slot name="table-busy">
          <div class="text-center text-danger my-2">
            <b-spinner
              variant="primary"
              label="Text Centered"
            />
          </div>
        </slot>
      </template>
    </b-table>
    <slot
      v-if="isPagination"
      name="pagination"
    >
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('table summary', { from: dataMeta.from, to: dataMeta.to, of: dataMeta.of }) }} </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </slot>
    <div v-if="hasFooter">
      <b-tfoot class="d-flex flex-column outer-footer-auto-search">
        <div
          v-if="isSearchable"
          class="w-100"
        >
          <autosuggest
            ref="autocomplete"
            v-model="searchQuery"
            class="l-tableList-collector__auto-suggest w-100"
            :suggestions="suggestions"
            :input-props="getSearchInputProps()"
            v-bind="getSuggestProps()"
            :table-suggestion="true"
            :table-suggestion-configs="tableSuggestionConfigs"
            :is-table-head="isSuggestionTableHead"
            :is-editable="isSearchInputDisabled"
            @input="fetchResults"
            @selected="selectHandler"
            @item-changed="resetSearchQueryOnClick"
          >
            <template slot="before-input">
              <b-spinner
                v-if="searchLoading"
                class="spinner"
                variant="secondary"
                small
              />
            </template>
            <template slot="after-input">
              <b-badge
                v-if="isAutoSuggestItemExists"
                class="badge"
                variant="warning"
              >
                Exists
              </b-badge>
              <b-badge
                v-if="isAutoSuggestItemFound"
                class="badge"
                variant="warning"
              >
                Not Found
              </b-badge>
            </template>
          </autosuggest>
        </div>
        <div v-else>
          <slot name="custom-autosuggest" />
        </div>
      </b-tfoot>
      <b-tfoot class="d-flex flex-column outer-footer-bottom">
        <b-tr>
          <b-th
            v-for="col in tableColumns"
            :key="col.key"
            scope="col"
            style="padding: 7px 12px"
            :style="col.tBodyThStyle"
          >
            <slot
              :name="footSlotName(col.key)"
              :data="col"
            >
              {{ }}
            </slot>
          </b-th>
        </b-tr>
      </b-tfoot>
    </div>
    <div v-else>
      <slot name="custom-table-footer" />
    </div>
  </div>
</template>

<script>

import {
  BTable, BRow, BCol,
  BPagination, BButton, BSpinner, BFormCheckbox, BTfoot, BBadge, BTh, BTr,

} from 'bootstrap-vue'
import Vue from 'vue'
import tableConfig from '@/libs/tableConfig'
import ButtonDashed from '@/@core/components/b-button-dashed/ButtonDashed.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import i18n from '@/libs/i18n'
import draggable from 'vuedraggable'
import { debounce, getValueFromGivenObjectByKey } from '@/@core/utils/utils'
import Autosuggest from './AutoSuggest/Autosuggest.vue'
// eslint-disable-next-line import/extensions
import AutoSuggestSection from './AutoSuggest/parts/AutoSuggestSection.js'

export default {
  name: 'LTableListCollector',
  components: {
    BTable,
    BRow,
    BTh,
    BTr,
    BCol,
    BPagination,
    BButton,
    BSpinner,
    ButtonDashed,
    Autosuggest,
    BFormCheckbox,
    BTfoot,
    BBadge,
    AutoSuggestSection,
    draggable,
  },
  props: {
    isRemoveHeader: {
      type: Boolean,
      default: false,
    },
    tableConfigOptions: {
      type: Object,
      default: () => ({}),
    },
    tableColumns: {
      type: Array,
      required: true,
    },
    fetchedData: {
      type: Array,
      default: () => [],
    },
    moduleName: {
      type: String,
      required: true,
    },
    isSearchInputDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    tableProps: {
      type: Object,
      default: () => ({}),
    },
    createPageUrl: {
      type: [Object, String],
      default: () => '',
    },
    searchProps: {
      type: Object,
      default: () => ({}),
    },
    searchInputProps: {
      type: Object,
      default: () => ({}),
    },
    isAutoSuggest: {
      type: Boolean,
      default: true,
    },
    isSuggestionTableHead: {
      type: Boolean,
      default: false,
    },
    isFooter: {
      type: Boolean,
      default: false,
    },
    tableExtraClasses: {
      type: String,
      default: () => '',
    },
    isPrint: {
      type: Boolean,
      default: false,
    },
    isImport: {
      type: Boolean,
      default: false,
    },
    isDelete: {
      type: Boolean,
      default: false,
    },
    isDeleteBtnDisabled: {
      type: Boolean,
      default: false,
    },
    filterClass: {
      type: [Object, null],
      default: null,
    },
    isSearchable: {
      type: Boolean,
      default: true,
    },
    itemIdForCheckItself: {
      type: [Number, String],
      default: () => null,
    },
    rowClicked: {
      type: [Function, String, null],
      default: null,
    },
    autosuggestListItemName: {
      type: String,
      default: () => 'name',
    },
    sortByTable: {
      type: String,
      default: () => 'id',
    },
    tableSuggestionConfigs: {
      type: Array,
      required: false,
      default: () => [],
    },
    showEmptyListTitle: {
      type: Boolean,
      default: false,
    },
    hasFooter: {
      type: Boolean,
      default: true,
    },
    isTableStripped: {
      type: Boolean,
      default: true,
    },
    additionalObjectForSelectedItem: {
      type: Object,
      default: () => {},
    },
    collectionOfListItemID: {
      type: String,
      default: () => 'id',
    },
    canExistedItem: {
      type: Boolean,
      default: true,
    },
    rowActive: {
      type: Object,
      default: () => ({
        filedKey: '',
        classes: '',
      }),
    },
  },
  data() {
    return {
      suggestions: [],
      collectionOfListArr: [...this.fetchedData],
      filterToggle: false,
      batchDeleteArr: [],
      autoSuggestLoading: false,
      isAutoSuggestItemExists: false,
      isAutoSuggestItemFound: false,
    }
  },
  computed: {
    searchLoading() {
      return this.autoSuggestLoading && this.searchQuery
    },
    headSlotNames() {
      return this.tableColumns.map(item => `head(${item.key})`)
    },
    footSlotNames() {
      return this.tableColumns.map(item => `foot(${item.key})`)
    },
    cellSlotNames() {
      return this.tableColumns.map(item => `cell(${item.key})`)
    },
    tableList() {
      return this.$store.state[this.moduleName][this.moduleName]
    },
    isAllChecked() {
      if (this.tableList.length) {
        return this.tableList?.length === this.batchDeleteArr.length
      }
      return false
    },
    isBatchDeleteArrNotEmpty() {
      return this.batchDeleteArr.length === 0
    },
  },
  updated() {
    this.$emit('updateHandler')
  },
  methods: {
    rowClassHandler(row) {
      // eslint-disable-next-line no-nested-ternary
      return row?.[this.rowActive?.filedKey] ? this.rowActive.classes : ''
    },
    getTableProps() {
      const defaultTableProps = {
        ref: `${this.moduleName}Table`,
        items: Array.from(this.collectionOfListArr),
        responsive: true,
        fields: this.tableColumns,
        'primary-key': 'id',
        'show-empty': this.showEmptyListTitle,
        'empty-text': this.$t('List Empty'),
        busy: this.isBusy,
        class: `position-relative l-table-list-collector ${this.tableExtraClasses}`,
        striped: this.isTableStripped,
        'foot-clone': this.isFooter,
      }
      if (this.rowClicked) {
        defaultTableProps.hover = true
      }
      return {
        ...defaultTableProps,
        ...this.tableProps,
      }
    },
    myRowClickHandler(data, row, el) {
      if (this.rowClicked) {
        if (typeof this.rowClicked === 'function') {
          this.rowClicked(data)
        } else {
          this.gotoNewPage({ name: this.rowClicked, path: `${data.id}`, params: { id: data.id } }, el)
        }
      }
    },
    rowHoveredHandler(data, row, el) {
      this.$emit('rowHoveredHandler', { data, row, el })
    },
    rowUnHoveredHandler() {
      this.$emit('rowUnHoveredHandler')
    },
    getSuggestProps() {
      const defaultProps = {
        'render-suggestion': this.renderSuggestion,
        'get-suggestion-value': this.getSuggestionValue,
      }
      return {
        ...defaultProps,
        ...this.searchProps,
      }
    },
    getSearchInputProps() {
      const defaultInputProps = {
        id: 'table-search',
        placeholder: 'Search...',
        class: 'form-control',
        name: 'table-search',
        style: { borderRadius: this.collectionOfListArr.length ? '0 0 5px 5px !important' : '5px !important' },
      }
      return {
        ...defaultInputProps,
        ...this.searchInputProps,
      }
    },
    renderSuggestion(suggestion) {
      return suggestion
    },
    getSuggestionValue(suggestion) {
      const { item } = suggestion
      return item.name
    },

    getSelectOptions(params) {
      this.suggestions = []
      this.autoSuggestLoading = true
      if (params && params.length > 2) {
        this.$store.dispatch(this.endpoint, {
          search: params,
          per_page: 50,
        }).then(res => {
          const { data } = this.getBody(res)

          if (data.length === 0) this.isAutoSuggestItemFound = true

          this.suggestions = [{ data }]
        }).finally(() => {
          this.autoSuggestLoading = false
          setTimeout(() => {
            this.isAutoSuggestItemFound = false
          }, 1000)
        })
      }
    },

    resetSearchQueryOnClick() {
      this.$refs.autocomplete.internalValue = null
    },
    fetchResults(searchQuery) {
      if (this.collectionOfListArr.length > 0 && searchQuery) {
        const existItem = this.collectionOfListArr?.find(collectionOfListItem => getValueFromGivenObjectByKey(collectionOfListItem, this.autosuggestListItemName).toLowerCase().split(' ').join('') === searchQuery.toLowerCase().split(' ').join(''))

        this.isAutoSuggestItemExists = !!existItem
      }

      this.search(searchQuery, this)
    },
    search: debounce((searchQuery, ctx) => {
      ctx.getSelectOptions(searchQuery)
    }, 500),

    selectHandler(suggest) {
      if (!suggest) return

      const { item } = suggest

      if (this.additionalObjectForSelectedItem) {
        // eslint-disable-next-line no-restricted-syntax
        for (const additionalItem in this.additionalObjectForSelectedItem) {
          item[additionalItem] = this.additionalObjectForSelectedItem[additionalItem]
        }
      }

      if (this.itemIdForCheckItself && !this.checkForAddingItself(item)) return

      const isExist = this.collectionOfListArr?.find(collectionOfListItem => getValueFromGivenObjectByKey(collectionOfListItem, Object.hasOwn(collectionOfListItem, this.collectionOfListItemID) ? this.collectionOfListItemID : 'id') === item.id)

      if (isExist && this.canExistedItem) {
        this.isAutoSuggestItemExists = true
        this.suggestions = []
        setTimeout(() => {
          this.isAutoSuggestItemExists = false
        }, 1000)
      } else {
        this.collectionOfListArr.push(item)
        this.suggestions = []
        this.$emit('getCollectedList', this.collectionOfListArr)
      }
    },
    checkForAddingItself(itemForCheck) {
      if (itemForCheck.id === this.itemIdForCheckItself) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('You can\'t add this product to itself'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return false
      }
      return true
    },
    importButtonHandler() {
      this.$emit('importButtonHandler')
    },
    deleteButtonHandler() {
      this.confirmNotification(this, this.batchDeleteArr, `${this.moduleName}/batchDelete`)
        .then(() => {
          this.refetchData()
          this.$nextTick(() => {
              // eslint-disable-next-line no-unused-expressions
              this.$refs?.deleteBtn.$el.setAttribute('disabled', true)
          })
        })
    },
    getAllIds() {
      if (this.tableList.length === this.batchDeleteArr.length) {
        this.batchDeleteArr = []
      } else if (this.batchDeleteArr.length === 0) {
        this.batchDeleteArr.push(...this.tableList.map(customer => customer.id))
      } else {
        // eslint-disable-next-line array-callback-return,consistent-return
        const filteredArr = this.tableList.map(customer => customer.id).filter(id => {
          if (!this.batchDeleteArr.includes(id)) {
            return id
          }
        })
        this.batchDeleteArr.push(...filteredArr)
      }
    },
    addToBatchArr(id) {
      if (this.batchDeleteArr.includes(id)) {
        this.batchDeleteArr = this.batchDeleteArr.filter(batchId => batchId !== id)
      } else {
        this.batchDeleteArr.push(id)
      }
    },
    isChecked(id) {
      return !!this.batchDeleteArr.find(batchId => batchId === id)
    },
    onFilter(filter) {
      // eslint-disable-next-line no-restricted-syntax,guard-for-in
      for (const field in filter) {
        this.extraParams[field] = filter[field]
      }
      this.filterToggle = false
      this.refetchData()
    },
    footSlotName(key) {
      return `foot(${key})`
    },
    // getColumns(){
    //   return this.tableColumns.map((item) => )
    // },
  },
  watch: {
    fetchedData: {
      immediate: true,
      // eslint-disable-next-line no-unused-vars
      handler(val, _) {
        if (val) {
          this.collectionOfListArr = val
        }
      },
    },
  },
  setup(props) {
    const { filterClass } = props
    if (filterClass && filterClass.filterComponent) {
      Vue.component(`${props.moduleName}FilterComponent`, () => filterClass.filterComponent)
    }
    return {
      ...tableConfig(props.moduleName, props.tableConfigOptions, props.filterClass),
    }
  },
}
</script>
<style scoped>
.filterTitle {
  margin-right: 8px;
  font-size: 16px;
}

.searchButton {
  margin: 0 0 0 16px !important;
  padding: 9px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';

.opacity-50 {
  opacity: 0.50;
}

// dont remove this style it is used in the table
.highlightRow{
  background: rgba(0, 177, 57, 0.15) !important;
  &:nth-child(even) {
    background: #00B1390D !important;
  }
}

.l-tableList-collector {
  .table-responsive.position-relative {
    margin-bottom: 0 !important;
  }

  .b-table-empty-row td {
    border-radius: 5px 5px 0 0 !important;
    border-bottom: none !important;
  }

  .headerTable * thead{
    visibility: hidden;
  }

  .headerTable{
    margin-top: -50px;
  }

  //td {
  //  border-radius: 0 !important;
  //}

  .table-striped tbody tr:first-child:not(.b-table-empty-row) td:first-child {
    border-radius: 5px 0 0 0 !important;
  }
  .table-striped tbody tr:first-child:not(.b-table-empty-row) td:last-child {
    border-radius: 0 5px 0 0 !important;
  }

  .autosuggest__results {
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #646464;
      border-radius: 5px;
    }
  }

  table.table tfoot tr th:last-child {
    border-right: solid 1px #dde3e7;
  }

  table.table tfoot tr th:first-child {
    border-left: solid 1px #dde3e7;
  }

  tfoot {
    display: revert;
  }

  &__auto-suggest {
    height: 40px;
    position: relative;

    & .spinner,
    & .badge {
      position: absolute !important;
    }

    & .spinner {
      right: 8px !important;
      top: 14px !important;
    }

    & .badge {
      right: 8px !important;
      top: 8px !important;
    }

    .form-control {
      height: 40px;

      &:focus {
        border: none;
      }
    }
  }

  .autosuggest__results {
    top: 3px;
    border-radius: 0.5rem;

    & tbody tr td {
      border-radius: 0 !important;
      border-right: none !important;
      border-left: none !important;
      border-bottom: none !important;
    }
    .table {
      margin-bottom: 0;
    }

    .table-striped tbody tr:first-child:not(.b-table-empty-row) td:first-child {
      border-radius: 0 !important;
    }
    .table-striped tbody tr:first-child:not(.b-table-empty-row) td:last-child {
      border-radius: 0 !important;
    }

    .autosuggest__results-item {
      padding: 7px 10px;
      cursor: pointer !important;
    }

    & thead tr th {
      padding: 7px 10px;
    }
  }

  .l-table-list-collector {
    overflow: hidden;

    & tbody tr td {
      padding: 5px 8px;
    }

    & .outer-footer-bottom {
      height: 36px;
    }

  }
    .outer-footer-auto-search {
      height: 36px;

      & .form-control {
        height: 100% !important;
      }
    }
}
</style>
